<template>
  <b-row
    align-h="center"
    class="mt-5"
  >
    <b-col md="8">
      <b-card header="Change Password">
        <validation-observer #default="{handleSubmit}">
          <b-form @submit.prevent>
            <validation-provider
              #default="validationContext"
              name="Current Password"
              rules="required"
            >
              <b-form-group
                label="Current Password"
                label-for="current_password"
              >
                <b-input-group>
                  <b-form-input
                    id="email"
                    v-model="newPassword.current_password"
                    placeholder="Current Password"
                    :type="passwordFieldType"
                    :state="getValidationState(validationContext)"
                  />
                  <template #append>
                    <b-input-group-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        size="15"
                        class="form-control-eye"
                        @click="togglePasswordVisibility()"
                      />
                    </b-input-group-text>
                  </template>
                </b-input-group>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="New Password"
              :rules="`required|password|is_not:${newPassword.current_password}`"
              vid="confirmed"
            >
              <b-form-group
                label="New Password"
                label-for="new_password"
              >
                <b-form-input
                  id="new_password"
                  v-model="newPassword.new_password"
                  placeholder="New Password"
                  :type="passwordFieldType"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Confirm Password"
              rules="required|confirmed:confirmed"
            >
              <b-form-group
                label="Confirm Password"
                label-for="new_password_confirmed"
              >
                <b-form-input
                  id="new_password_confirmed"
                  v-model="newPassword.new_password_confirmation"
                  placeholder="Confirm Password"
                  :type="passwordFieldType"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <submit-button
              :handle-submit="handleSubmit"
              :submit="changePassword"
            />

            <back />
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Back from '@/common/components/common/FormInputs/Back.vue'
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'

export default {
  name: 'ChangePassword',
  components: {
    Back,
    SubmitButton,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      passwordFieldType: 'password',
      newPassword: {},
    }
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()

    const {
      getValidationState,
    } = formValidation()

    return {
      successfulOperationAlert, getValidationState,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  methods: {
    changePassword() {
      return this.$portalUsers.post('v1/change-password', this.newPassword).then(() => {
        this.successfulOperationAlert('Password is changed successfully')
      })
    },
  },
}
</script>
<style lang="scss">

</style>
